import React from 'react'
import { Link } from 'react-router-dom'

export const HomeHero = () => {
  return (
    <section className='h-[100vh] homeHeroImg pt-[18vh] md:bg-center'>
      <h1 className='text-[4rem] text-center text-white font-thin text-primary'>
        V &amp; B <span className='text-[2.9rem] lg:text-[4rem]'>Construction</span>
      </h1>
      <div className='w-[300px] text-white  mx-auto mt-[20px] p-[10px] text-center lg:w-[475px]'>
        <h2 className='text-[1.8rem] text-secondary font-bold'>Residential General Construction</h2>
        <p className='text-[1.2rem] text-subtitle'>V &amp; B Construction offers full construction services to Los Angeles and Orange County.</p>
        <Link to='/contact-us'>
          <div className='text-[1.6rem] text-white bg-black rounded-[20px] w-fit py-[5px] px-[10px] mx-auto my-[20px]'>Contact us</div>
        </Link>
      </div>
    </section>
  )
}
