import React from 'react'
import deckBefore from '../../../assets/remodeling/pooldeck1.JPEG'
import deckAfter from '../../../assets/remodeling/pooldeck.JPEG'

export const BeforeAndAfter = () => {
  return (
    <section>
      <h3 className='text-[3rem] text-secondary text-center'>Let Us Handle the Home Projects</h3>
      <p className='text-[1.3rem] w-[280px] mx-auto text-center text-subtitle md:w-[400px]'>
        There is no project too big or too small, we get the job done efficiently.
      </p>
      <div className='flex flex-wrap gap-[20px] my-[30px]'>
        <div className='w-[280px] mx-auto'>
          <img className='w-[280px]  rounded-[10px]' src={deckBefore} alt='Pool deck being made' />
          <p className='imgCaption lg:text-[1.1rem]'>Before</p>
        </div>
        <div className='w-[280px] mx-auto'>
          <img className='w-[280px]  rounded-[10px]' src={deckAfter} alt='Pool deck completed' />
          <p className='imgCaption lg:text-[1.1rem]'>After</p>
        </div>
      </div>
    </section>
  )
}
