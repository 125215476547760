import React from 'react'
import { MainServicesData } from '../../../Data/MainServicesData'
import { Link } from 'react-router-dom'

export const Services = () => {
  return (
    <section>
      <h2 className='text-[3rem] text-secondary mt-[60px] text-center font-thin'>Services</h2>
      <div className='flex flex-wrap gap-[20px] justify-evenly'>
        {MainServicesData.map(info => (
          <div className='relative'>
            <Link to={info.link}>
              <img
                className='w-[280px] mx-auto h-[140px] object-cover object-center my-[20px] rounded-[20px]'
                src={info.image}
                alt={info.description}
              />
            </Link>
            <Link to={info.link}>
              <h3 className='absolute top-0 left-0 right-0 bottom-0 m-auto w-fit h-fit text-[2rem] bg-[rgba(0,0,0,.6)] py-[5px] px-[10px] rounded-[10px] text-white text-secondary font-bold text-subtitle'>
                {info.description}
              </h3>
            </Link>
          </div>
        ))}
      </div>
    </section>
  )
}
