import React from "react";
import "./Gallery2.css";
import { GalleryUrls } from "../gallery/GalleryData2";
import { Link } from "react-router-dom";

export const GalleryNav = () => {
  return (
    <>
      <nav className="gallery-nav-container">
        <div className="gallery-nav">
          {GalleryUrls.map((i) => {
            return (
              <>
                <Link to={i.Url} className="gallery-list">
                  {i.titleUrl}
                </Link>
              </>
            );
          })}
        </div>
      </nav>
    </>
  );
};
