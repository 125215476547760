import './App.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { Home } from './Views/HomeView/Home'
import ScrollToTop from './ScrollToTop'
import { Contact } from './Views/ContactView/Contact'
import Footer from './components/footer'
// gallery below comment out when not in use
// import { Gallery2 } from "./pages/gallery2/Gallery2";
import { Flooring2 } from './pages/gallery2/Flooring2'
import { Roofing2 } from './pages/gallery2/Roofing2'
import { Remodeling2 } from './pages/gallery2/Remodeling2'
import { Welding2 } from './pages/gallery2/Welding2'
import { Navbar } from './components/Navbar/Navbar'

function App() {
  return (
    <div className='App'>
      <Router>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/contact-us' element={<Contact />} />
          <Route path='/flooring' element={<Flooring2 />} />
          <Route path='/roofing' element={<Roofing2 />} />
          <Route path='/welding' element={<Welding2 />} />
          <Route path='/remodeling' element={<Remodeling2 />} />
          {/* Gallery Below comment out if not in use */}
          {/* <Route path="/gallery" element={<Gallery2 />} /> */}
        </Routes>
        <Footer />
      </Router>
    </div>
  )
}

export default App
