import React from "react";
import { RemodelingData2 } from "../gallery/GalleryData2";
import { GalleryNav } from "./GalleryNav";

export const Remodeling2 = () => {
  return (
    <>
      <GalleryNav />
      <section className="gallery-img-container2">
        <h2 className="gallery-title2">Remodeling</h2>
        {RemodelingData2.map((e) => {
          return <img className="gallery-img2" src={e.image} alt={e.alt}></img>;
        })}
      </section>
    </>
  );
};
