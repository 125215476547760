import React from 'react'
import floor from '../../../assets/flooring/floor4.JPEG'
import roof from '../../../assets/roofing/flatroof.JPG'
import remodel from '../../../assets/remodeling/pooldeck3.JPEG'
import welding from '../../../assets/welding/metalgate.JPEG'

export const About = () => {
  return (
    <section className='mb-[5vh]'>
      <h2 className='text-[3rem] text-secondary font-thin text-center'>About Us</h2>
      <div className='flex flex-wrap gap-[20px] justify-center items-center'>
        <p className='w-[280px] mx-auto text-center text-subtitle mt-[20px] md:w-[320px] lg:text-[1.5rem] lg:w-[500px] '>
          We strive to get your project completed efficiently and effectively. We want you to know that you are in trusted hands. Therefore we will
          talk over the plan and timing. To avoid any miscommunications we write everything down to make sure everything is accounted for.
        </p>
        <div className='w-[280px] mx-auto'>
          <div>
            <div className='flex'>
              <img className='w-[140px] mx-auto rounded-tl-[10px] border-2 border-[#2b2b2b]' src={floor} alt='Flooring in Progress' />
              <img className='w-[140px] mx-auto rounded-tr-[10px] border-2 border-[#2b2b2b]' src={roof} alt='Flooring in Progress' />
            </div>
            <div className='flex'>
              <img className='w-[140px] mx-auto rounded-bl-[10px] border-2 border-[#2b2b2b]' src={remodel} alt='Flooring in Progress' />
              <img className='w-[140px] mx-auto rounded-br-[10px] border-2 border-[#2b2b2b]' src={welding} alt='Flooring in Progress' />
            </div>
          </div>
          <p className='imgCaption lg:text-[1.1rem]'>Job Samples</p>
        </div>
      </div>
    </section>
  )
}
