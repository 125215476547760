import { useState } from 'react'

export const Contact = () => {
  const [phoneNumber, setPhoneNumber] = useState('')

  const handlePhoneNumberChange = event => {
    let input = event.target.value
    input = input.replace(/\D/g, '')
    input = input.substring(0, 10)

    if (input.length > 0) {
      if (input.length <= 3) {
        input = `(${input}`
      } else if (input.length <= 6) {
        input = `(${input.substring(0, 3)}) ${input.substring(3)}`
      } else {
        input = `(${input.substring(0, 3)}) ${input.substring(3, 6)}-${input.substring(6)}`
      }
    }

    input = input.replace(/(\d{3})\D*(\d{3})\D*(\d{4})/, '$1) $2-$3')
    setPhoneNumber(input)
  }
  return (
    <section className='h-[90vh] flex flex-wrap justify-center items-center'>
      <h3 className=' basis-full text-[1.5rem] text-subtitle text-center lg:text-[2.4rem]'>Let Us Take Care of Your Home Projects</h3>
      <form
        className='bg-[#e3dad5] w-[280px] mx-auto p-[15px] rounded-[20px] md:w-[400px]'
        action='https://formsubmit.co/5136378984f232b6dc0d927ae17fe734'
        method='POST'
      >
        <div className='w-fit'>
          <input type='hidden' name='_subject' value='Construction Job!'></input>
          <label for='name' className='text-subtitle'>
            Full Name:
          </label>
          <input
            className='text-subtitle py-[5px] px-[10px] rounded-[20px] w-[250px] mb-[10px] md:w-[370px]'
            id='name'
            type='text'
            name='name'
            placeholder='John Doe'
            required
          ></input>
          <label for='message' className='text-subtitle'>
            Phone Number:
          </label>
          <input
            id='number'
            className='text-subtitle py-[5px] px-[10px] rounded-[20px] w-[250px] mb-[10px] md:w-[370px]'
            type='tel'
            name='number'
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            placeholder='(optional)'
          ></input>
          <label for='email' className='text-subtitle'>
            Email Address:
          </label>
          <input
            className='text-subtitle py-[5px] px-[10px] rounded-[20px] w-[250px] mb-[10px] md:w-[370px]'
            id='email'
            type='email'
            name='email'
            placeholder='example@email.com'
            required
          ></input>
          <input type='hidden' name='_next' value='https://vandbconstruction.com'></input>
          <label for='message' className='text-subtitle'>
            Type of job:
          </label>
          <input
            className='text-subtitle py-[5px] px-[10px] rounded-[20px] w-[250px] mb-[10px] md:w-[370px]'
            id='message'
            type='text'
            name='message'
            placeholder='Roofing...,Flooring...,etc'
            required
          ></input>
          <div className='w-fit mx-auto'>
            <button className='text-subtitle bg-black text-white py-[5px] px-[10px] rounded-[10px] text-[1.2rem]' type='submit'>
              Send
            </button>
          </div>
        </div>
      </form>
    </section>
  )
}
