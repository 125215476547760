import React from 'react'
import { Link } from 'react-router-dom'

export const CoverageArea = () => {
  return (
    <section>
      <h3 className='text-[3rem] text-secondary text-center'>Areas Where We Work</h3>
      <p className='text-subtitle text-center text-[1.1rem] w-[280px] mx-auto md:w-[420px]'>
        Long Beach, Seal Beach, Orange, Carson, Paramount, Lakewood, Cerritos, Signal Hill, Bellflower, Hawaiian Gardens, Cypress, Stanton, Garden
        Grove, Huntington Beach, Buena Park and any nearby cities.
      </p>
      <Link to='/contact-us'>
        <div
          className='text-[1.2rem] text-secondary text-black mx-auto my-[15px] border-t-2 border-b-2 border-black w-fit md:text-[1.6rem]
        '
        >
          Get an estimate
        </div>
      </Link>
    </section>
  )
}
