export const FlooringData = [
  {
    image: require("../../assets/flooring/floor.JPG"),
    alt: "grey toned vinyl floor",
  },
  {
    image: require("../../assets/flooring/floor2.JPG"),
    alt: "elegant grey toned vinyl floor",
  },
  {
    image: require("../../assets/flooring/floor3.JPG"),
    alt: " modern looking kitchen with grey vinyl floor",
  },
  {
    image: require("../../assets/flooring/floor4.JPEG"),
    alt: "brown and grey vinyl floor being installed",
  },
];
export const RemodelingData2 = [
  {
    image: require("../../assets/remodeling/deck.JPG"),
    alt: "Beige Deck on second floor being made",
  },
  {
    image: require("../../assets/remodeling/pooldeck.JPEG"),
    alt: "Grey pool deck finished with white and copper red brick accents",
  },
  {
    image: require("../../assets/remodeling/pooldeck1.JPEG"),
    alt: "Pool deck framing being made",
  },
  {
    image: require("../../assets/remodeling/pooldeck2.JPEG"),
    alt: "Framing for pool deck being created",
  },
  {
    image: require("../../assets/remodeling/pooldeck3.JPEG"),
    alt: "Grey pool deck stairs finished",
  },
  {
    image: require("../../assets/remodeling/pooldeck4.JPEG"),
    alt: "Grey pool deck finished result",
  },
  {
    image: require("../../assets/remodeling/fence1.JPEG"),
    alt: "Posts for fences being cemented in place",
  },
  {
    image: require("../../assets/remodeling/fence2.JPEG"),
    alt: "Wood fence being being made",
  },
  {
    image: require("../../assets/remodeling/fence3.JPEG"),
    alt: "Wood fence in progress of installing",
  },
  {
    image: require("../../assets/remodeling/fence4.JPEG"),
    alt: "Wood fence finished",
  },
  {
    image: require("../../assets/remodeling/fence5.JPEG"),
    alt: "Wood fence in the middle almost finished",
  },
  {
    image: require("../../assets/remodeling/fence6.JPEG"),
    alt: "Wood fence with space for the wood gate to be installed",
  },
];
export const RoofingData2 = [
  {
    image: require("../../assets/roofing/flatroof.JPG"),
    alt: "Torch down roof being installed on a flat roof",
  },
  {
    image: require("../../assets/roofing/roof1.JPEG"),
    alt: "Roof plywood installed",
  },
  {
    image: require("../../assets/roofing/roof2.JPEG"),
    alt: "Copper coloured roof installed",
  },
  {
    image: require("../../assets/roofing/roof5.JPEG"),
    alt: "Copper roof ridge installed",
  },
  {
    image: require("../../assets/roofing/shingles.JPG"),
    alt: "White shingles roof installed with ridge",
  },
  {
    image: require("../../assets/roofing/tileroof1.JPEG"),
    alt: "Tile roof being removed to replace water damaged wood",
  },
  {
    image: require("../../assets/roofing/tileroof2.JPEG"),
    alt: "Tile roof finished after repairs",
  },
  {
    image: require("../../assets/roofing/patio1.JPEG"),
    alt: "Old patio before being demolished",
  },
  {
    image: require("../../assets/roofing/patio2.JPEG"),
    alt: "Patio gone after being demolished",
  },
];
export const WeldingData2 = [
  {
    image: require("../../assets/welding/metalgate.JPEG"),
    alt: "Custom metal gate before being installed",
  },
  {
    image: require("../../assets/welding/stairs.JPEG"),
    alt: "New stairs for apartments with new metal railings",
  },
  {
    image: require("../../assets/welding/stairs2.JPEG"),
    alt: "Metal railing in the process of being welded",
  },
  {
    image: require("../../assets/welding/stairs3.JPEG"),
    alt: "New stairs before the metal railing was created and installed",
  },
];
export const GalleryUrls = [
  {
    Url: "/flooring",
    titleUrl: "Flooring",
  },
  {
    Url: "/roofing",
    titleUrl: "Roofing",
  },
  {
    Url: "/welding",
    titleUrl: "Welding",
  },
  {
    Url: "/remodeling",
    titleUrl: "Remodeling",
  },
];
