import React from "react";
import { FlooringData } from "../gallery/GalleryData2";
import "./Gallery2.css";
import { GalleryNav } from "./GalleryNav";

export const Flooring2 = () => {
  return (
    <>
      <GalleryNav />
      <section id="floor" className="gallery-img-container2">
        <h2 className="gallery-title2">Flooring</h2>
        {FlooringData.map((e) => {
          return (
            <>
              <img className="gallery-img2" src={e.image} alt={e.alt}></img>
            </>
          );
        })}
      </section>
    </>
  );
};
