import "./components.css";
const Footer = () => {
  return (
    <>
      <footer className="footer">
        <span className="copyright">
          Created and Designed by Olmen Vicente || All Rights Reserved
        </span>
      </footer>
    </>
  );
};

export default Footer;
