export const MainServicesData = [
  {
    image: require('../assets/flooring/floor.JPG'),
    description: 'Flooring',
    link: '/flooring',
  },
  {
    image: require('../assets/remodeling/pooldeck.JPEG'),
    description: 'Remodeling',
    link: '/remodeling',
  },
  {
    image: require('../assets/roofing/flatroof.JPG'),
    description: 'Roofing',
    link: '/roofing',
  },
  {
    image: require('../assets/welding/stairs.JPEG'),
    description: 'Welding',
    link: 'welding',
  },
]
