import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/vandblogo2.png'

export const Navbar = () => {
  return (
    <nav className=' w-full h-[10vh]  flex items-center'>
      <Link to='/'>
        <img className='w-[80px] ml-[10px]' src={logo} alt='Logo for V and B Construction' />
      </Link>
    </nav>
  )
}
