import React from 'react'
import { Link } from 'react-router-dom'

export const CTA = () => {
  return (
    <section className='bg-[#e3dad5] pt-[5vh] pb-[10vh]'>
      <h3 className='text-[3rem] text-secondary text-center'>Start Your Project Now</h3>
      <Link to='/contact-us'>
        <div className='text-[2rem] text-center bg-black w-fit mx-auto py-[5px] px-[10px] rounded-[20px] text-white mt-[45px] text-subtitle'>
          Contact Us
        </div>
      </Link>
    </section>
  )
}
