import React from 'react'
import { HomeHero } from './HomeComponents/HomeHero'
import { Services } from './HomeComponents/Services'
import { About } from './HomeComponents/About'
import { CTA } from './HomeComponents/CTA'
import { CoverageArea } from './HomeComponents/CoverageArea'
import { BeforeAndAfter } from './HomeComponents/BeforeAndAfter'

export const Home = () => {
  return (
    <>
      <HomeHero />
      <Services />
      <div className=' border-t border-[#454545] w-[70%] mx-auto my-[5vh]'></div>
      <About />
      <div className=' border-t border-[#454545] w-[70%] mx-auto my-[5vh]'></div>
      <CTA />
      <div className=' border-t border-[#454545] w-[70%] mx-auto my-[5vh]'></div>
      <BeforeAndAfter />
      <div className=' border-t border-[#454545] w-[70%] mx-auto my-[5vh]'></div>
      <CoverageArea />
    </>
  )
}
